import React from 'react';
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import People from '../../components/people';

export const peopleContext = React.createContext()


export default function PeoplePage({ location, data }) {


  return (
    <Layout location={location}>
      <peopleContext.Provider value={data} >
        <People location={location} />
      </peopleContext.Provider>
    </Layout>
  )
}

export const fetchAllPeopleQuery = graphql`
{
  allPeople {
    people {
      id
      name
      role
      profile_pic {
        url
      }
      partner_priority
      mentor_priority
      advisor_priority
      about
      designation
      linkedin_url
    }
  }
}
`;