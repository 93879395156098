import React from 'react';
import PeopleTabs from './people-tabs'
import PeopleHero from './people_hero'


export default function People({location, data }) {

    return (
        <>
            <PeopleHero />
            <PeopleTabs  location={location}  />

        </>

    );
};