import React, { useContext } from "react";
import PeopleList from "./people_list"
import { peopleContext } from '../pages/people/[id]'

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

const PeopleTabs = (location) => {

let url = location.location.pathname;
let index = parseInt(url.charAt(url.length - 1))

    const peopleData = useContext(peopleContext).allPeople.people

    const partnerData = peopleData.filter((person) => { return person.role === "Partner" })
    const partnerDatas = partnerData.sort(dynamicSort('partner_priority'))

    const mentorData = peopleData.filter((person) => { return person.role === "Mentor" })
    const mentorDatas = mentorData.sort(dynamicSort('mentor_priority'))

    const advisorData = peopleData.filter((person) => { return person.role === "Advisor" })
    const advisorDatas = advisorData.sort(dynamicSort('advisor_priority'))

    const [openTab, setOpenTab] = React.useState(index);

    function handleBackToHome(index) {
        setOpenTab(index)
      }

    return (
        <>
            <div className="relative">
                <div className=" rounded-3xl flex justify-center mx-auto w-90v sm:w-90v  md:w-80v lg:w-80v  ">
                    <ul
                        className="flex mb-0 list-none flex-col pt-3 pb-4 space-y-2 sm:space-y-0 sm:flex-row "
                    >
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a
                                className={
                                    "text-sm font-bold  px-8 py-3 block leading-normal text-black border-b-2 border-white " +
                                    (openTab === 1
                                        ? " border-orange-primary"
                                        : " bg-sky-50")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Investment Team
                            </a>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a
                                className={
                                    "text-sm font-bold  px-8 py-3 block leading-normal text-black border-b-2 border-white " +
                                    (openTab === 2
                                        ? " border-orange-primary"
                                        : "bg-sky-50")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Advisors
                            </a>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a
                                className={
                                    "text-sm font-bold px-16 py-3 block leading-normal text-black border-b-2 border-white" +
                                    (openTab === 3
                                        ? " border-orange-primary"
                                        : " bg-sky-50")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(3);
                                }}
                                data-toggle="tab"
                                href="#link3"
                                role="tablist"
                            >
                                Mentors
                            </a>
                        </li>
                    </ul>
                </div>


                <div className="w-full">
                    <div className=" flex bg-light-blue sm:mb-6">
                        <div className=" py-5 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <PeopleList key="1" data={partnerDatas}  />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <PeopleList key="2" data={advisorDatas}  />

                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                    <PeopleList key="3" data={mentorDatas}  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};
export default PeopleTabs